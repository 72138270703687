<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
    <path
      d="M26.65,16A12.63,12.63,0,1,1,14,3.32V16Z"
      fill="none"
      stroke="#00785f"
    />
    <path
      d="M21.07,2.82a8.7,8.7,0,0,1,1.19.39,8.45,8.45,0,0,1,4.5,4.5,8.28,8.28,0,0,1,.39,1.19H21.07V2.82m-2-2.24V10.9H29.39A10.28,10.28,0,0,0,28.6,7,10.51,10.51,0,0,0,26.37,3.6a10.39,10.39,0,0,0-7.3-3Z"
      fill="#00785f"
    />
  </svg>
</template>

<template>
  <div class="flex justify-between">
    <div
      v-for="item in data"
      v-once
      :key="item.text"
      class="flex items-center gap-4 text-green-swamp"
    >
      <div class="h-7 w-7 bg-gray flex">
        <component :is="item.icon" class="w-4 m-auto" stroke-width="2" />
      </div>
      <span class="uppercase text-xs lg:text-sm font-semibold">
        {{ item.text }}
      </span>
    </div>
  </div>
</template>

<script setup>
const { t } = useI18n()
const data = [
  {
    icon: resolveComponent('IconsStarV2'),
    text: t('total_brands')
  },
  {
    icon: resolveComponent('IconsTag'),
    text: t('Офіційна гарантія та сервіс')
  },
  {
    icon: resolveComponent('IconsShieldCheck'),
    text: t('Знижки для військових')
  },
  {
    icon: resolveComponent('IconsAboutWorld'),
    text: t('Програми кредитування')
  }
]
</script>

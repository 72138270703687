<template>
  <div>
    <div
      v-if="props.count > 0 && props.show"
      class="px-2.5 py-1.5 sm:p-2.5 border border-gray flex items-center justify-between"
    >
      <div class="text-green-swamp flex flex-col">
        <span class="text-xs font-bold mb-1">
          {{ t('В кошику') }}
          {{ productsAmount(props.count) }}
        </span>
        <span class="text-[10px] sm:text-xs">
          {{ t('На сумму') }} {{ priceFormatter(props.totalCost, false) }} грн
        </span>
      </div>
      <button
        :disabled="cart?.cannotCheckoutOrder"
        class="checkout-link"
        @click="checkout"
      >
        {{ t('Оформити замовлення') }}
      </button>
      <div class="sm:hidden" @click="emits('close')">
        <XMarkIcon class="w-3.5 ml-2.5 text-neutral-400" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon } from '@heroicons/vue/24/solid'
import { useCartStore } from '~~/stores/cart'
import { useAuthStore } from '~~/stores/auth'

const cartStore = useCartStore()
const { cart } = storeToRefs(cartStore)

const props = defineProps({
  cartList: {
    type: Array,
    default: () => []
  },
  count: {
    type: Number,
    default: 0
  },
  totalCost: {
    type: Number,
    default: 0
  },
  show: {
    type: Boolean,
    default: true
  }
})

const checkout = () => {
  if (useAuthStore().loggedInStore) {
    return navigateTo(useLocalePath()('/checkout-store/'))
  }
  return navigateTo(useLocalePath()('/checkout/'))
}

const { t } = useI18n()
const emits = defineEmits(['close'])

const priceFormatter = usePriceFormatter()
const productsAmount = useProductsAmount()
</script>

<style lang="postcss" scoped>
.checkout-link {
  @apply btn btn-primary text-[10px] leading-[1.1] sm:leading-3 sm:text-xs uppercase py-2.5 sm:py-3.5 sm:px-[22px] w-28 sm:w-60 text-center font-semibold sm:font-bold ml-auto;
}
</style>
